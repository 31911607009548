import { useApolloClient } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'


import Notification from '../../../components/ui/notification'
import AuthContext from '../../../context/auth'

import LinkCard from '../../../components/Home/LinkCard'
import Card, { CardContent } from '../../../components/ui/card'
import Container from '../../../components/ui/container'
import Divider from '../../../components/ui/divider'
import Loader from '../../../components/ui/loading'
import Typography from '../../../components/ui/typography'

import VisibleOn, { HiddenOn } from '../../../components/hoc/visible-on'

import MaintenanceJob from '../../../components/OwnersArea/ParkServices/MaintenanceJob'
import MaintenanceJobRequest from '../../../components/OwnersArea/ParkServices/MaintenanceJobRequest'
import {datadogLogger} from "../../../utils/datadogLogger/logger";
import { GET_INITIAL_DATA, SET_MAINTENANCE_JOB } from './graphql'

import PagedResults from '../../../components/PagedResults'
import AccountContext from '../../../context/accounts'
import Theme from '../../../context/theme'
import useLineColor from '../../../context/theme/hooks/useLineColor'
import { paymentSuccessPrd, paymentSuccessTest, paymentFailurePrd, paymentFailureTest, paymentClientPrd, paymentClientStaging } from '../../../config/urls'

import { parkCodeFromId } from '../../../helpers'

const initialJobState = {
  jobType: '',
  permissionToEnter: '',
  jobPrice: 0,
}

const ParkServices = props => {
  const { theme } = React.useContext(Theme)
  const {ownership} = useContext(AccountContext);
  const { user } = useContext(AuthContext)
  const {
    match: {
      params: { customerNo },
    },
    isProduction,
  } = props

  const parkId = (ownership.find(o => o.customerNo === customerNo) || {})
    .parkId

  const [isLoading, setIsLoading] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [newJob, setNewJob] = useState(initialJobState)
  const [bookedJobs, setBookedJobs] = useState([])
  const [allJobs, setAllJobs] = useState([])
  const [notification, setNotification] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [error, setError] = useState(null)
  const client = useApolloClient()
  const axios = require('axios').default

  const returnUrl = isProduction ? paymentSuccessPrd : paymentSuccessTest
  const failureReturnUrl = isProduction ? paymentFailurePrd : paymentFailureTest
  const paymentClient = isProduction ? paymentClientPrd : paymentClientStaging

  useEffect(() => {
    let mounted = true
    setIsLoading(true)
    const getInitialData = async () => {
      try {
        const { data } = await client.query({
          query: GET_INITIAL_DATA,
          variables: { customerNo },
        })

        if (mounted) {
          setBookedJobs(data.customerMaintenanceJobs || [])

          const filteredJobs = [];
          data.allMaintenanceJobTypes.forEach(job => {
            filteredJobs.push(job);
          })
          setAllJobs(filteredJobs)
          setIsLoading(false)
        }
      } catch (err) {
        if (mounted) {
          setNotification({
            context: 'error',
            title: 'Error',
            messages: err.graphQLErrors.map(error => error.message),
          })
          setIsLoading(false)
        }
      }
    }

    getInitialData()
    return () => {
      mounted = false
    }
  }, [client, customerNo])

  const handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setNewJob({ ...newJob, ...{ [e.target.name]: value } })
    setShowPayment(false)
  }

  // insert payment stuff here
  // draindown etc
  const createMaintenanceJob = async () => {
    const jobDetails = allJobs.find(job => job.code === newJob.jobType)
    setShowPayment(false)

    try {
      const { data } = await client.mutate({
        mutation: SET_MAINTENANCE_JOB,
        variables: {
          input: {
            customerNo,
            description: jobDetails.description,
            typeCode: jobDetails.code,
            reportedBy: user.email,
            permissionToEnterUnit: newJob.permissionToEnter ? true : false ,
          },
        },
      })

      datadogLogger.log(`SET_MAINTENANCE_JOB mutation response ${data}`)

      setNotification({
        context: 'success',
        title: 'Service Requested',
        messages: [
          `We have successfully received your request for "${data.createMaintenanceJob.description}"`,
        ],
      })

      setBookedJobs([data.createMaintenanceJob, ...bookedJobs].filter(i => !i))
    } catch (err) {
      datadogLogger.error(`SET_MAINTENANCE_JOB error response ${JSON.stringify(err)}`)
      // console.log(err.graphQLErrors)
      setNotification({
        context: 'error',
        title: 'Error',
        messages: err?.graphQLErrors?.map(error => error?.message),
      })
    }

    setNewJob(initialJobState)
  }

  const lineColor = useLineColor();

  const paymentFailed = () => {
    setError(
      "Your card has not been authorised, please check the details and retry or contact your bank.",
    );
  }

  return (
    <>
      <div
        style={{ borderTop: `4px solid ${lineColor}`, height: 0 }}
      />
      <Container
        style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
      >
        {notification ? (
          <Notification
            title={notification.title}
            messages={notification.messages}
            color={notification.color}
            rounded={false}
          />
        ) : null}

        <Card color="white" rounded={false}>
          <CardContent style={{ paddingTop: 16, paddingBottom: 0 }}>
            <Typography as="h3" weight="bold" noMargins>
              Park Services
              <span style={{ fontWeight: 400 }}> - Book & Pay</span>
            </Typography>
            <Divider style={{ marginTop: 16, marginBottom: 1 }} />
          </CardContent>
        </Card>

        <>
        <Typography as="p">
          {error ? error : null }
        </Typography>

        <MaintenanceJobRequest
          maintenanceJobs={allJobs}
          handleChange={handleChange}
          newJob={newJob}
          handlePayment={e => {
            e.preventDefault()

            setShowPayment(true)

            console.log('Initialise DNA payments widget');

            //Add datetime to customer number to make unique ref?
            var str = new Date().toISOString();
            var res = str.replaceAll(/\.[0-9]{3}|T|Z|-|:/g, '');
            var uniqueRef = `${customerNo}-${res}`;

            // Create data object
            const data = {
              amount: parseFloat(newJob.jobPrice),
              reference: uniqueRef,
              parkCode: parkCodeFromId(parkId),
              paymentDescription: `Job payment for customer ${customerNo}`,
            };

            // Initialise widget
            window.DNAPayments.configure({
              isTestMode: !isProduction,
              isEnableDonation: false,
              paymentMethods: [
                  {
                      name: window.DNAPayments.paymentMethods.BankCard,
                      message: ''
                  }
              ],
              events: {
                      opened: () => {
                          console.log('Checkout opened')
                      },
                      cancelled: () =>  {
                          console.log('Transaction cancelled')
                      },
                      paid: () => {
                          console.log('Transaction successful')
                          createMaintenanceJob()
                          window.DNAPayments.closePaymentWidget()
                      },
                      declined: () => {
                          console.log('Transaction declined')
                          paymentFailed()
                          window.DNAPayments.closePaymentWidget()
                      }
              },
              embeddedWidget: {
                  container: '#dna-embedded-widget-container',
                  widgetType: 'EMBEDDED',
                  maxVisiblePaymentMethods: 3,
                  orderButtonText: 'Pay £' + data.amount.toLocaleString("en-GB", { minimumFractionDigits: 2 })
              }
            });

              console.log('Authentication request using data', data);

              const graphqlQuery = {
                query: `query PaymentClient {
                    owners_payment_client(park_code: "${data.parkCode}" invoice_id: "${data.reference}" amount: "${data.amount}") {
                        access_token
                        expires_in
                        refresh_token
                        scope
                        token_type
                        terminal_id
                    }
                }`
              }

              axios({
                url: paymentClient,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(graphqlQuery)
              })
              .then(function (response) {

                  console.log('Initialise widget with', response);
                  window.DNAPayments.openEmbeddedWidget({
                      invoiceId: data.reference,
                      amount: data.amount,
                      currency: "GBP",
                      description: data.paymentDescription,
                      paymentSettings:  {
                          returnUrl:   returnUrl,
                          failureReturnUrl:   failureReturnUrl,
                          //callbackUrl:   'http://3101-92-28-165-169.ngrok.io/dna-validate-callback',
                          //failureCallbackUrl:   'hhttp://3101-92-28-165-169.ngrok.io/dna-validate-callback',
                          terminalId: response.data.data.owners_payment_client.terminal_id
                      },
                      customerDetails: data.customerDetails,
                      auth: response.data.data.owners_payment_client
                  })

              }).catch(function (error) {
                  console.log('Authorization error');
                  // handle error
                  if (error.response) {
                    setError(error.response.data); //May need changing depending on how the authorization request sends errors
                  } else {
                    setError(error);
                  }
              });

          }}
        />

        {showPayment ? (
          <div
            style={{
              marginBottom: theme.spacing(2),
              padding: `0 ${theme.spacing(2)}px`,
            }}
          >
            <div id="dna-embedded-widget-container"></div>
          </div>
        ) : null}
        </>

        <Card
          rounded={false}
          style={{
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            marginBottom: theme.spacing(2),
          }}
        >
          <CardContent>
            <Typography as="h2" weight="bold" noMargins>
              Get ready for Winter
            </Typography>
            <Typography as="p" style={{ marginTop: 8, marginBottom: 0 }}>

            </Typography>
            <LinkCard to="https://www.parkholidays.com/caravan-holiday-homes-for-sale/drain-down-request-form">
              Book your Holiday Home Drain Down Service by clicking here
            </LinkCard>
          </CardContent>
        </Card>

        <Card
          color="white"
          rounded={false}
          style={{
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
          }}
        >
          <CardContent>
            <Typography as="h2" weight="bold" noMargins>
              Booked Services Status
            </Typography>
          </CardContent>
        </Card>

        {isLoading ? (
          <Loader
            sector="ownership"
            style={{
              position: 'relative',
              padding: theme.spacing(2),
              border: 'none',
            }}
          />
        ) : (
          <>
            <VisibleOn
              breakpoints={['xs', 'sm']}
              component={
                <PagedResults
                  items={bookedJobs}
                  itemComponent={MaintenanceJob}
                  itemKey="no"
                  isMobile
                  currentPage={currentPage}
                  customMessage="You currently have no booked services"
                  onPageChange={setCurrentPage}
                />
              }
            />

            <HiddenOn
              breakpoints={['xs', 'sm']}
              component={
                <PagedResults
                  items={bookedJobs}
                  itemComponent={MaintenanceJob}
                  itemKey="no"
                  currentPage={currentPage}
                  customMessage="You currently have no booked services"
                  onPageChange={setCurrentPage}
                />
              }
            />
          </>
        )}
      </Container>
    </>
  )
}

ParkServices.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ customerNo: PropTypes.string }),
  }).isRequired,
}

export default ParkServices
