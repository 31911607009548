import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import AuthContext from './context/auth'
import {isParkLeisureContext} from "./helpers/hostNameCheck";
import Layout from './layout'
import routes from './routes'
import { PageNotFound, Migration } from './views'
import { dnaPaymentsPrd, dnaPaymentsTest } from './config/urls'
//test1
const authenticatedRoutePropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  auth: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  routeProps: PropTypes.object,
}

const authenticatedRouteDefaultProps = { routeProps: {} }

const AuthenticatedRoute = ({
  component: Component,
  auth,
  routeProps,
  ...rest
}) => {
  const location = useLocation()
  const goToProfile =
    auth.user && auth.user.sub.contactNo === null && location.pathname !== '/profile'
  let redirectPath = goToProfile ? '/profile' : '/login'
  if (auth.forcePasswordChange) redirectPath = '/login'
  const redirect = auth.isGuest || auth.forcePasswordChange || goToProfile ? true : false

  return (
    <Route
      {...rest}
      render={props =>
        redirect ? (
          <Redirect
            to={{ pathname: redirectPath, state: { from: location } }}
          />
        ) : (
          <Component {...props} {...routeProps} />
        )
      }
    />
  )
}

const App = ({ isProduction }) => {
  const auth = useContext(AuthContext)
  const location = useLocation()

  let newRoutes = [...routes];
  const ableLinksOwner = auth.user && auth.user.parkIds.includes(58)
  // const hostIsParkLeisureStaging = window.location.hostname === "my-staging-pl.parkholidays.com" //process.env.OWNERS_PL_STAGING_HOSTNAME
  // const hostIsParkLeisureProduction = window.location.hostname === "my.parkleisure.co.uk" //process.env.OWNERS_PL_PRODUCTION_HOSTNAME
 console.log('isParkLeisureContext', isParkLeisureContext())
  if (ableLinksOwner && isParkLeisureContext()) {
    // console.log('AL owner');
    // And if user is a specific email address (for testing purposes only)
    if (auth.user.email === 'bydowyke@ema-sofia.eu') {
      // console.log('Display migration');
      // Modify routes, remove any non-public and add the migration notice as '/'
      newRoutes = newRoutes
      .filter(route => route.isPublic)
      .concat([{ path: '/', component: Migration, hideLayout: true, isPublic: true }])
    }
  }

  React.useEffect(() => {
    const src = isProduction ? dnaPaymentsPrd : dnaPaymentsTest;
    const script = document.createElement('script');
    script.src = src
    document.body.appendChild(script);
  }, [isProduction])

  useEffect(() => {
    auth.verifySession();

  }, [location.pathname, auth])

  return (
    <Layout routes={newRoutes} isLoggedIn={!auth.isGuest}>
      <Switch>
        {newRoutes.map(route => {
          const Component = route.component
          return route.isPublic ? (
            <Route
              exact
              path={route.path}
              key={route.path}
              render={props => (
                <Component 
                {...props} 
                {...(route.props || {})} 
                isProduction={isProduction}
                />
              )}
            />
          ) : (
            <AuthenticatedRoute
              exact
              path={route.path}
              key={route.path}
              component={route.component}
              auth={auth}
              routeProps={{...route.props, isProduction,}}
            />
          )
        })}

        <Route component={PageNotFound} />
      </Switch>
    </Layout>
  )
}

AuthenticatedRoute.propTypes = authenticatedRoutePropTypes
AuthenticatedRoute.defaultProps = authenticatedRouteDefaultProps
export default App
