import { useApolloClient } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import Balance from '../../../components/OwnersArea/Balance'
import Button from '../../../components/ui/button'
import Card, { CardContent, CardHeader } from '../../../components/ui/card'
import Checkbox from '../../../components/ui/checkbox'
import Container from '../../../components/ui/container'
import Divider from '../../../components/ui/divider'
import FormField from '../../../components/ui/formfield'
import FormLabel from '../../../components/ui/formlabel'
import Grid from '../../../components/ui/grid'
import Loading from '../../../components/ui/loading'
import Select from '../../../components/ui/select'
import Typography from '../../../components/ui/typography'
import AccountContext from '../../../context/accounts'
import Theme from '../../../context/theme'
import useLineColor from '../../../context/theme/hooks/useLineColor'
import { ownerBalance, parkCodeFromId } from '../../../helpers'
import { GET_OWNER_DETAILS, PAY_INVOICE } from './graphql'
import { datadogLogger } from "../../../utils/datadogLogger/logger";

import { paymentSuccessPrd, paymentSuccessTest, paymentFailurePrd, paymentFailureTest, paymentClientPrd, paymentClientStaging } from '../../../config/urls'

/**
 * To Do
 * 1) What if you pay too much - i.e. pay multiple times as balance hasn't altered.
 */

const BalancePayment = ({ match, history, isProduction }) => {
  console.log('isProduction ?????', isProduction);
  const axios = require('axios').default
  const { theme } = useContext(Theme)
  const {ownership} = useContext(AccountContext)
  const { customerNo } = match.params
  const [paymentAmount, setPaymentAmount] = useState(0)
  const [ownerDetails, setOwnerDetails] = useState({})
  const [loading, setLoading] = useState(true)
  const [paymentType, setPaymentType] = useState('full')
  const [paymentComplete, setPaymentComplete] = useState(false)
  const [showBillingFields, setShowBillingFields] = useState(false);
  const nameSplit = ownerDetails.name?.split(" ");   // Blunt attempt to extract first and surname from name string
  const [firstname, setFirstname] = useState(nameSplit ? nameSplit[0] : '');
  const [surname, setSurname] = useState(nameSplit ? nameSplit[nameSplit.length-1] : '');
  const [email, setEmail] = useState(ownerDetails.email);
  const [addressLine1, setAddress1] = useState(ownerDetails.address);
  const [postcode, setPostcode] = useState(ownerDetails.postcode);
  const [city, setCity] = useState(ownerDetails.city);
  const [country, setCountry] = useState('GB'); // Quick fix, always set to GB
  const [error, setError] = useState()

  const countryCodes = [
    ['AF', 'Afghanistan'],
    ['AL', 'Albania'],
    ['AD', 'Andorra'],
    ['AG', 'Antigua and Barbuda'],
    ['AN', 'Antilles (Netherlands)'],
    ['AR', 'Argentina'],
    ['AM', 'Armenia'],
    ['AW', 'Aruba'],
    ['AU', 'Australia'],
    ['AT', 'Austria'],
    ['AZ', 'Azerbaijan'],
    ['BH', 'Bahrain'],
    ['BS', 'Bahamas'],
    ['BE', 'Belgium'],
    ['BD', 'Bangladesh'],
    ['BB', 'Barbados'],
    ['BY', 'Belarus'],
    ['BZ', 'Belize'],
    ['BJ', 'Benin'],
    ['BM', 'Bermuda'],
    ['BT', 'Bhutan'],
    ['BO', 'Bolivia'],
    ['BA', 'Bosnia and Herzegovina'],
    ['BW', 'Botswana'],
    ['BR', 'Brazil'],
    ['BG', 'Bulgaria'],
    ['BN', 'Brunei'],
    ['BF', 'Burkina Faso'],
    ['BI', 'Burundi'],
    ['KH', 'Cambodia'],
    ['CM', 'Cameroon'],
    ['CA', 'Canada'],
    ['CV', 'Cape Verde'],
    ['KY', 'Cayman Islands'],
    ['CF', 'Central African Republic'],
    ['TD', 'Chad'],
    ['CL', 'Chile'],
    ['CN', 'China'],
    ['CO', 'Colombia'],
    ['KM', 'Comoros'],
    ['CD', 'Congo, The Democratic Republic'],
    ['CK', 'Cook Islands'],
    ['CR', 'Costa Rica'],
    ['HR', 'Croatia'],
    ['CU', 'Cuaba'],
    ['CY', 'Cyprus'],
    ['CZ', 'Czech Republic'],
    ['DK', 'Denmark'],
    ['DJ', 'Djibouti'],
    ['DM', 'Dominica'],
    ['DO', 'Dominican Republic'],
    ['EC', 'Ecuador'],
    ['EG', 'Egypt'],
    ['SV', 'El Salvador'],
    ['GQ', 'Equatorial Guinea'],
    ['ER', 'Eritrea'],
    ['EE', 'Estonia'],
    ['ET', 'Ethiopia'],
    ['FJ', 'Fiji'],
    ['FI', 'Finland'],
    ['FR', 'France'],
    ['GF', 'French Guiana'],
    ['GY', 'Guyana'],
    ['PF', 'French Polynesia'],
    ['GA', 'Gabon'],
    ['GM', 'Gambia'],
    ['GE', 'Georgia'],
    ['DE', 'Germany'],
    ['GH', 'Ghana'],
    ['GI', 'Gibraltar'],
    ['GR', 'Greece'],
    ['GD', 'Grenada'],
    ['GP', 'Guadeloupe'],
    ['GT', 'Guatemala'],
    ['GN', 'Guinea'],
    ['GW', 'Guinea-Bissau'],
    ['GY', 'Guyana'],
    ['HT', 'Haiti'],
    ['HN', 'Honduras'],
    ['HK', 'Hong Kong'],
    ['HU', 'Hungary'],
    ['IS', 'Iceland'],
    ['IN', 'India'],
    ['ID', 'Indonesia'],
    ['IR', 'Iran'],
    ['IQ', 'Iraq'],
    ['IE', 'Ireland'],
    ['IL', 'Israel'],
    ['IT', 'Italy'],
    ['CI', 'Ivory Coast'],
    ['JM', 'Jamaica'],
    ['JP', 'Japan'],
    ['JO', 'Jordan'],
    ['KZ', 'Kazakhstan'],
    ['KE', 'Kenya'],
    ['XK', 'Kosovo'],
    ['KW', 'Kuwait'],
    ['KG', 'Kyrgyzstan'],
    ['LV', 'Latvia'],
    ['LA', 'Lao'],
    ['LB', 'Lebanon'],
    ['LS', 'Lesotho'],
    ['LR', 'Liberia'],
    ['LY', 'Libya'],
    ['LI', 'Lichtenstein'],
    ['LT', 'Lithuania'],
    ['LU', 'Luxembourg'],
    ['MO', 'Macao'],
    ['MK', 'Macedonia'],
    ['MG', 'Madagascar'],
    ['MW', 'Malawi'],
    ['MY', 'Malaysia'],
    ['MV', 'Maldives'],
    ['ML', 'Mali'],
    ['MT', 'Malta'],
    ['MQ', 'Martinique'],
    ['MR', 'Mauritania'],
    ['MU', 'Mauritius'],
    ['MX', 'Mexico'],
    ['FM', 'Micronesia (Federated States)'],
    ['MD', 'Moldova'],
    ['MC', 'Monaco'],
    ['MN', 'Mongolia'],
    ['ME', 'Montenegro'],
    ['MA', 'Morocco'],
    ['MZ', 'Mozambique'],
    ['MM', 'Myanmar'],
    ['NA', 'Namibia'],
    ['NR', 'Nauru'],
    ['NP', 'Nepal'],
    ['NL', 'Netherlands'],
    ['NZ', 'New Zealand'],
    ['NI', 'Nicaragua'],
    ['NE', 'Niger'],
    ['NG', 'Nigeria'],
    ['KP', 'North Korea'],
    ['NO', 'Norway'],
    ['OM', 'Oman'],
    ['PK', 'Pakistan'],
    ['PW', 'Palau'],
    ['PS', 'Palestine'],
    ['PA', 'Panama'],
    ['PG', 'Papua New Guinea'],
    ['PY', 'Paraguay'],
    ['PE', 'Peru'],
    ['PH', 'Philippines'],
    ['PL', 'Poland'],
    ['PT', 'Portugal'],
    ['PR', 'Puerto Rico'],
    ['QA', 'Qatar'],
    ['RE', 'Reunion'],
    ['RO', 'Romania'],
    ['RU', 'Russian Federation'],
    ['RW', 'Rwanda'],
    ['KN', 'Saint Kitts And Nevis'],
    ['LC', 'Saint Lucia'],
    ['VC', 'Saint Vincent And The Grenadines'],
    ['WS', 'Samoa'],
    ['SM', 'San Marino'],
    ['ST', 'Sao Tome and Principe'],
    ['SA', 'Saudi Arabia'],
    ['SN', 'Senegal'],
    ['RS', 'Serbia'],
    ['SC', 'Seychelles'],
    ['SL', 'Sierra Leone'],
    ['SG', 'Singapore'],
    ['SK', 'Slovakia'],
    ['SI', 'Slovenia'],
    ['SB', 'Solomon Islands'],
    ['SO', 'Somalia'],
    ['ZA', 'South Africa'],
    ['KR', 'South Korea'],
    ['ES', 'Spain'],
    ['LK', 'Sri Lanka'],
    ['SD', 'Sudan'],
    ['SR', 'Suriname'],
    ['CH', 'Switzerland'],
    ['SE', 'Sweden'],
    ['SY', 'Syria'],
    ['TW', 'Taiwan'],
    ['TJ', 'Tajikisten'],
    ['TZ', 'Tanzania'],
    ['TH', 'Thailand'],
    ['TG', 'Togo'],
    ['TO', 'Tonga'],
    ['TT', 'Trinidad And Tobago'],
    ['TN', 'Tunisia'],
    ['TR', 'Turkey'],
    ['TM', 'Turkmenistan'],
    ['TC', 'Turks and Caicos Islands'],
    ['UG', 'Uganda'],
    ['UA', 'Ukriane'],
    ['AE', 'United Arab Emirates'],
    ['GB', 'United Kingdom'],
    ['US', 'United States of America'],
    ['UY', 'Uruguay'],
    ['UZ', 'Uzbekistan'],
    ['VA', 'Vatican'],
    ['VE', 'Venezuela'],
    ['VN', 'Vietnam'],
    ['VG', 'Virgin Islands, British'],
    ['YE', 'Yemen'],
    ['ZM', 'Zambia'],
    ['ZW', 'Zimbabwe']
  ];

  const { balance } = ownerDetails;

  const selectedAccount = ownership.find(
    owner => owner.customerNo === customerNo,
  )
  const client = useApolloClient()

  const customerBalance = balance ? ownerBalance(balance).split(' ') : null
  const balanceValue = customerBalance ? customerBalance[0] : 0
  const balanceType = customerBalance ? customerBalance[1].toLowerCase() : null
  const minPaymentAmount = parseInt(
    process.env.REACT_APP_MINIMUM_PAYMENT_AMOUNT,
    10,
  )

  const maxPaymentAmount = Math.abs(
    balance,
  ) /*parseInt(
    process.env.REACT_APP_MAXIMUM_PAYMENT_AMOUNT,
    10,
  )*/
  const maxAmountPayable = Math.min(Math.abs(balance), maxPaymentAmount)

  // STYLES
  const styles = {
    header: {
      card: {
        marginBottom: theme.spacing(1),
      },
      cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: 0,
      },
      cardContent: { paddingBottom: theme.spacing(1.5) },
      divider: { marginTop: 0 },
    },
  }

  const getOutstandingAmount = value => (value < 0 ? Math.abs(value) : 0)

  const returnUrl = isProduction ? paymentSuccessPrd : paymentSuccessTest
  const failureReturnUrl = isProduction ? paymentFailurePrd : paymentFailureTest
  const paymentClient = isProduction ? paymentClientPrd : paymentClientStaging

  React.useEffect(() => {
    let mounted = true
    const getInitialData = async () => {
      try {
        const { data } = await client.query({
          query: GET_OWNER_DETAILS,
          variables: { customerNo },
        })

        if (mounted) {
          setPaymentAmount(
            Math.min(
              getOutstandingAmount(data.ownerDetails.balance),
              maxPaymentAmount,
            ),
          )

          setOwnerDetails(data.ownerDetails)
        }
      } catch (err) {
        if (mounted)
          setError(
            'An undefined error has occured. Please refresh the page and try again.',
          )
      }

      if (mounted) setLoading(false)
    }

    getInitialData()

    return () => {
      mounted = false
    }
  }, [client, customerNo, maxPaymentAmount])

  React.useEffect(() => {

    const paymentTaken = async () => {
      try {
        await client.mutate({
          mutation: PAY_INVOICE,
          variables: {
            input: {
              domain: 'my.parkholidays.com',
              customerNo,
              reference: customerNo,
              invoices: [
                {
                  no: '',
                  amount: parseFloat(paymentAmount.toString()),
                },
              ],
            },
          },
        })
      } catch (err) {
        datadogLogger.error(`Balance payment error ${JSON.stringify(err)} ${typeof err}`)
        setError(
          "Your payment has been taken but this hasn't been deducted from your balance. Please login after sometime to see the updated balance",
        )
      } finally {
        setPaymentComplete(true)
      }
    }

    const paymentFailed = () => {
      setError(
        "Your card has not been authorised, please check the details and retry or contact your bank.",
      );
      setPaymentComplete(true)
    }

    console.log('Initialise DNA payments widget');

    if (paymentAmount >= minPaymentAmount) { // Validate

        //Add datetime to customer number to make unique ref?
        var str = new Date().toISOString();
        var res = str.replaceAll(/\.[0-9]{3}|T|Z|-|:/g, '');
        var uniqueRef = `${customerNo}-${res}`;

      // Create data object
      const data = {
        amount: parseFloat(paymentAmount),
        reference: uniqueRef,
        parkCode: parkCodeFromId(selectedAccount.parkId),
        paymentDescription: `Payment for customer ${customerNo}`,
        customerDetails: {
            billingAddress:  {
                firstName: firstname,
                lastName: surname,
                addressLine1: addressLine1,
                postalCode: postcode,
                city: city,
                country: country
            },
            email: email
        }
      };

      // Initialise widget
      window.DNAPayments.configure({
        isTestMode: !isProduction,
        isEnableDonation: false,
        paymentMethods: [
            {
                name: window.DNAPayments.paymentMethods.BankCard,
                message: ''
            }
        ],
        events: {
                opened: () => {
                    console.log('Checkout opened')
                },
                cancelled: () =>  {
                    console.log('Transaction cancelled')
                },
                paid: () => {
                    console.log('Transaction successful')
                    paymentTaken()
                    window.DNAPayments.closePaymentWidget()
                },
                declined: () => {
                    console.log('Transaction declined')
                    paymentFailed()
                    window.DNAPayments.closePaymentWidget()
                }
        },
        embeddedWidget: {
            container: '#dna-embedded-widget-container',
            widgetType: 'EMBEDDED',
            maxVisiblePaymentMethods: 3,
            orderButtonText: 'Pay £' + data.amount.toLocaleString("en-GB", { minimumFractionDigits: 2 })
        }
      });

      console.log('Authentication request using data', data);

      const graphqlQuery = {
        query: `query PaymentClient {
            owners_payment_client(park_code: "${data.parkCode}" invoice_id: "${data.reference}" amount: "${data.amount}") {
                access_token
                expires_in
                refresh_token
                scope
                token_type
                terminal_id
            }
        }`
      }

      axios({
        url: paymentClient,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(graphqlQuery)
      })
      .then(function (response) {

          console.log('Initialise widget with', response);
          window.DNAPayments.openEmbeddedWidget({
              invoiceId: data.reference,
              amount: data.amount,
              currency: "GBP",
              description: data.paymentDescription,
              paymentSettings:  {
                  returnUrl:  returnUrl,
                  failureReturnUrl: failureReturnUrl ,
                  //callbackUrl:   'http://3101-92-28-165-169.ngrok.io/dna-validate-callback',
                  //failureCallbackUrl:   'hhttp://3101-92-28-165-169.ngrok.io/dna-validate-callback',
                  terminalId: response.data.data.owners_payment_client.terminal_id
              },
              customerDetails: data.customerDetails,
              auth: response.data.data.owners_payment_client
          })

      }).catch(function (error) {
          console.log('Authorization error');
          // handle error
          if (error.response) {
            setError(error.response.data); //May need changing depending on how the authorization request sends errors
          } else {
            setError(error);
          }
      });

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressLine1, city, country, email, firstname, paymentAmount, postcode, surname]);

  const validateAmounts = () => {
    if (paymentAmount < minPaymentAmount) setPaymentAmount(minPaymentAmount)
    if (paymentAmount > maxPaymentAmount) setPaymentAmount(maxPaymentAmount)
  }

  const handleChange = e => {
    if (e.target.name === 'paymentType') {
      setPaymentType(e.target.value)
      if (e.target.value === 'full') {
        setPaymentAmount(maxAmountPayable)
      }
    }

    if (
      e.target.name === 'customAmount' &&
      (/^\s*\d+(\.\d{1,2})?\s*$/.test(e.target.value) || e.target.value === '')
    ) {
      const customAmount = e.target.value ? e.target.value : 0
      const customPayAmount =
        customAmount <= maxAmountPayable ? customAmount : maxAmountPayable
      console.log('Update payment amount');
      setPaymentAmount(customPayAmount)
    }

    if (e.target.name === 'billingFields') {
      setShowBillingFields(!showBillingFields);
    }

    if (e.target.name === 'firstname') setFirstname(e.target.value);
    if (e.target.name === 'surname') setSurname(e.target.value);
    if (e.target.name === 'email') setEmail(e.target.value);
    if (e.target.name === 'addressLine1') setAddress1(e.target.value);
    if (e.target.name === 'city') setCity(e.target.value);
    if (e.target.name === 'postcode') setPostcode(e.target.value);
    if (e.target.name === 'country') setCountry(e.target.value);

  }

  const formattedBalance = balance
    ? balance.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })
    : null

  const formattedFullAmountPayable = maxAmountPayable
    ? maxAmountPayable.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
      })
    : null

  const fullPaymentLabel =
    maxPaymentAmount <= Math.abs(balance) ? 'Maximum amount' : 'Full payment'

    const lineColor = useLineColor()

  return (
    <>
      <div
        style={{ borderTop: `4px solid ${lineColor}`, height: 0 }}
      />
      <Container style={{ marginTop: theme.spacing(2) }}>
        {/* HEADER */}
        <Card color="white" rounded={false} style={styles.header.card}>
          <CardHeader style={styles.header.cardHeader}>
            <Typography as="h3" weight="bold" noMargins>
              Make a Payment
            </Typography>
            <Typography as="small" transform="uppercase">
              {customerNo}
            </Typography>
          </CardHeader>

          <CardContent style={styles.header.cardContent}>
            <Divider style={styles.header.divider} />

            {loading ? null : (
              <Balance value={balanceValue} type={balanceType} />
            )}
          </CardContent>
        </Card>

        {paymentComplete ? (
          <>
            <Typography as="p">
              {error
                ? error
                : 'Your payment has been taken. Please be aware payments may take 48 hours to appear on your statement.'}
            </Typography>

            {!error ? (
              <Button
                onClick={() => history.push('/')}
                style={{ marginBottom: 16 }}
              >
                Back to My Account
              </Button>
            ) : null}
          </>
        ) : null}

        {loading ? (
          <Loading />
        ) : (
          <>
            {balance < 0 && (
              <>
                <Grid alignItems="center">
                  {!paymentComplete && (
                    <>
                      <Grid item xs={6} md={4}>
                        <FormLabel
                          htmlFor="full-payment-type"
                          control={
                            <Checkbox
                              as="radio"
                              id="full-payment-type"
                              name="paymentType"
                              value="full"
                              onChange={handleChange}
                              checked={paymentType === 'full'}
                            />
                          }
                        >
                          {fullPaymentLabel}
                        </FormLabel>
                      </Grid>

                      <Grid item xs={6} md={8}>
                        <Typography as="strong">
                          {formattedFullAmountPayable}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} md={4}>
                        <FormLabel
                          htmlFor="custom-payment-type"
                          control={
                            <Checkbox
                              as="radio"
                              id="custom-payment-type"
                              name="paymentType"
                              value="custom"
                              onChange={handleChange}
                              checked={paymentType === 'custom'}
                            />
                          }
                        >
                          Other Amount
                        </FormLabel>
                      </Grid>

                      <Grid item xs={6} md={6}>
                        {paymentType === 'custom' && (
                          <FormField
                            type="number"
                            min={minPaymentAmount}
                            max={maxAmountPayable}
                            id="custom-amount"
                            name="customAmount"
                            value={paymentAmount > 0 ? paymentAmount : ''}
                            helperText={`The minimum partial payment amount is £${process.env.REACT_APP_MINIMUM_PAYMENT_AMOUNT}`}
                            onChange={handleChange}
                            onBlur={validateAmounts}
                            fullWidth
                            beforeEnhancer={
                              <Typography
                                as="strong"
                                style={{
                                  padding: 16,
                                  paddingRight: 0,
                                  borderRadius: 4,
                                  border: '1px solid #d9d9d9',
                                  backgroundColor: '#fff',
                                  borderRight: '1px solid #fff',
                                }}
                              >
                                £
                              </Typography>
                            }
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <FormLabel
                          htmlFor="show-billing-fields"
                          control={
                            <Checkbox
                              as="checkbox"
                              id="show-billing-fields"
                              name="billingFields"
                              onChange={handleChange}
                              checked={showBillingFields}
                            />
                          }
                        >
                          Show billing address details
                        </FormLabel>
                      </Grid>

                      {showBillingFields && (
                        <>
                        <Grid item xs={6} md={4}>
                          <FormLabel
                          htmlFor="firstname"
                          labelPosition='top'
                          control={
                            <FormField
                              type="text"
                              id="firstname"
                              name="firstname"
                              value={firstname}
                              onChange={handleChange}
                            />
                          }>
                            First name
                          </FormLabel>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <FormLabel
                          htmlFor="surname"
                          labelPosition='top'
                          control={
                            <FormField
                              type="text"
                              id="surname"
                              name="surname"
                              value={surname}
                              onChange={handleChange}
                            />
                          }>
                            Surname
                          </FormLabel>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <FormLabel
                          htmlFor="email"
                          labelPosition='top'
                          control={
                            <FormField
                              type="text"
                              id="email"
                              name="email"
                              value={email}
                              onChange={handleChange}
                            />
                          }>
                            Email
                          </FormLabel>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <FormLabel
                          htmlFor="addressLine1"
                          labelPosition='top'
                          control={
                            <FormField
                              type="text"
                              id="addressLine1"
                              name="addressLine1"
                              value={addressLine1}
                              onChange={handleChange}
                            />
                          }>
                            Address Line 1
                          </FormLabel>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <FormLabel
                          htmlFor="postcode"
                          labelPosition='top'
                          control={
                            <FormField
                              type="text"
                              id="postcode"
                              name="postcode"
                              value={postcode}
                              onChange={handleChange}
                            />
                          }>
                            Postcode
                          </FormLabel>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <FormLabel
                          htmlFor="city"
                          labelPosition='top'
                          control={
                            <FormField
                              type="text"
                              id="city"
                              name="city"
                              value={city}
                              onChange={handleChange}
                            />
                          }>
                            Town / City
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12} md={4}>

                            <Select
                            id="country"
                            name="country"
                            label="Country"
                            labelProps={{style: {textAlign: 'center'}}}
                            value={country}
                            onChange={handleChange}
                            options={countryCodes.map((countryArray) => ({
                              label: countryArray[1],
                              value: countryArray[0],
                            }))}
                            />
                        </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>

                {!paymentComplete && paymentAmount ? (
                  <div
                    style={{
                      overflow: 'hidden',
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <div id="dna-embedded-widget-container"></div>
                  </div>
                ) : null}
              </>
            )}

            {balance > 0 && !paymentComplete && (
              <Typography as="p">{`You are ${formattedBalance} in credit`}</Typography>
            )}
          </>
        )}
      </Container>
    </>
  )
}

BalancePayment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ customerNo: PropTypes.string }),
  }).isRequired,
}

export default BalancePayment
